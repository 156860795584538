<template>
  <q-card
    flat
    square
    class="bg-blue-1"
  >
    <q-card-section>
      <div class="row">
        <div class="flex">
          <q-avatar
            rounded
            color="white"
            text-color="primary"
            icon="category"
          ></q-avatar>
          <div class="q-px-md flex items-center">{{ $t('MBO.CREATE_RISK_LEVEL_CATEGORY') }}</div>
        </div>
        <q-space></q-space>
        <q-btn
          v-if="hasFeature(Feature.riskLevelCategoriesWrite)"
          :class="['q-my-sm', 'self-center', { 'full-width': $q.screen.lt.sm }]"
          :label="$t('MBO.ADD_NEW_RISK_LEVEL_CATEGORY')"
          color="primary"
          icon="add"
          @click="onAddCategoryClick()"
        ></q-btn>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';

  export default defineComponent({
    setup() {
      const router = useRouter();
      return {
        hasFeature,
        Feature,
        onAddCategoryClick() {
          router.push({ name: 'risk-level.categories.category' });
        },
      };
    },
  });
</script>
