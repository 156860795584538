<template>
  <router-view></router-view>
</template>
<script>
  import { defineComponent, onUnmounted } from 'vue';
  import Store from '@/store';
  import CountriesStore from '@/components/risk-level/countries/countries.store';

  export default defineComponent({
    beforeRouteEnter(to, from, next) {
      Store.registerModule(CountriesStore.name, CountriesStore.module);
      next();
    },
    setup() {
      onUnmounted(() => {
        Store.unregisterModule(CountriesStore.name);
      });
    },
  });
</script>
