<template>
  <footer>
    <q-separator></q-separator>
    <div class="q-px-md q-py-xs flex">
      <q-space></q-space>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.BACK')"
        class="q-ma-xs"
        @click="onBackClick"
      ></q-btn>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.SAVE')"
        class="q-ma-xs"
        color="primary"
        @click="onSaveClick()"
      ></q-btn>
    </div>
  </footer>
</template>
<script>
  import { defineComponent, computed, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import i18n from '@/i18n';
  import { Error } from '@/common/constants/error';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import CountryStore from '@/components/risk-level/countries/country/country.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const $confirm = inject('$confirm');
      const riskLevelCountry = computed(() => state[CountriesStore.name][CountryStore.name].riskLevelCountry);

      async function saveRiskLevelCountry() {
        await dispatch(`${CountriesStore.name}/${CountryStore.name}/saveRiskLevelCountry`, riskLevelCountry.value);
        $q.notify(t('MBO.RISK_LEVEL_COUNTRY_WAS_SAVED'));
        await router.push({ name: 'risk-level.countries.search' });
      }
      return {
        onBackClick() {
          router.push({ name: 'risk-level.countries.search' });
        },
        async onSaveClick() {
          props.validations.riskLevelCountry.$touch();
          if (props.validations.riskLevelCountry.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
            return;
          }
          try {
            await saveRiskLevelCountry();
          } catch ({ response }) {
            if (response.status === Error.status409) {
              const category = response.data.category.name[i18n.global.locale];
              const source = response.data.source.name[i18n.global.locale];
              const country = response.data.country.name[i18n.global.locale];
              const result = await $confirm({
                title: `${t('MBO.RISK_LEVEL_DUPLICATE_CONFIRMATION_TITLE', { riskLevel: response.data.riskLevel })}`,
                content: `${t('MBO.RISK_LEVEL_DUPLICATE_CONFIRMATION_CONTENT', { riskLevel: response.data.riskLevel, category, source, country })}`,
              });
              if (result) {
                commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`, {
                  ...riskLevelCountry.value,
                  id: response.data.id,
                });
                await saveRiskLevelCountry();
              }
            }
          }
        },
      };
    },
  });
</script>
