<template>
  <div :class="$style.component">
    <div class="q-pa-md overflow-auto">
      <header-component></header-component>
      <form-component :validations="v$"></form-component>
    </div>
    <q-space></q-space>
    <footer-component :validations="v$"></footer-component>
  </div>
</template>
<script>
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import HeaderComponent from '@/components/risk-level/countries/country/components/header.component.vue';
  import FormComponent from '@/components/risk-level/countries/country/components/form.component.vue';
  import FooterComponent from '@/components/risk-level/countries/country/components/footer.component.vue';
  import Store from '@/store';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import CountryStore from '@/components/risk-level/countries/country/country.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const { id } = to.params;
        if (id) {
          await Store.dispatch(`${CountriesStore.name}/${CountryStore.name}/getRiskLevelCountryById`, id);
        } else {
          Store.commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`);
        }
        next();
      } catch (error) {
        next({ name: 'risk-level.countries.search' });
        throw error;
      }
    },
    setup() {
      const { state } = useStore();
      const v$ = useVuelidate();
      const riskLevelCountry = computed(() => state[CountriesStore.name][CountryStore.name].riskLevelCountry);
      return {
        v$,
        riskLevelCountry,
      };
    },
    validations() {
      return {
        riskLevelCountry: {
          source: {
            code: { required },
          },
          category: {
            code: { required },
          },
          country: {
            code: { required },
          },
          riskLevel: { required },
        },
      };
    },
    components: {
      HeaderComponent,
      FormComponent,
      FooterComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/item-component';

  .component {
    @include item-component();
  }
</style>
