<template>
  <div :class="$style.component">
    <header-component></header-component>
    <table-component class="q-pa-md"></table-component>
  </div>
</template>
<script>
  import { defineComponent, inject } from 'vue';
  import { startAutoRefreshing } from '@/common/chunks/auto-refresh';
  import HeaderComponent from '@/components/risk-level/countries/search/components/header.component.vue';
  import TableComponent from '@/components/risk-level/countries/search/components/table.component.vue';
  import Store from '@/store';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import SearchStore from '@/components/risk-level/countries/search/search.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const { searchObj } = Store.state[CountriesStore.name][SearchStore.name].searchInput;
        await Store.dispatch(`${CountriesStore.name}/${SearchStore.name}/getRiskLevelCountries`, searchObj);
        next();
      } catch (error) {
        next(false);
        throw error;
      }
    },
    setup() {
      startAutoRefreshing({
        dispatchName: `${CountriesStore.name}/${SearchStore.name}/getRiskLevelCountries`,
        searchState: Store.state[CountriesStore.name][SearchStore.name],
        autoRefresh: inject('$autoRefresh'),
      });
    },
    components: {
      HeaderComponent,
      TableComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/search-component';

  .component {
    @include search-component();
  }
</style>
