<template>
  <div class="q-gutter-md">
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <q-select
          v-model="sourceModel"
          :options="sourceOptions"
          :error="v$.riskLevelCountry.source.code.$error"
          :label="$t(`MBO.RISK_LEVEL_SOURCE`)"
          :option-label="optionLabel"
          option-value="code"
          hide-bottom-space
          outlined
          dense
        ></q-select>
      </div>
    </div>
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <q-select
          v-model="categoryModel"
          :options="categoryOptions"
          :error="v$.riskLevelCountry.category.code.$error"
          :label="$t(`MBO.RISK_LEVEL_CATEGORY`)"
          :option-label="optionLabel"
          option-value="code"
          hide-bottom-space
          outlined
          dense
        ></q-select>
      </div>
    </div>
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <em-country-select
          v-model="riskLevelCountry.country"
          :error="v$.riskLevelCountry.country.$error"
          :label="$t('MBO.RISK_LEVEL_COUNTRY')"
          hide-bottom-space
          return-object
        ></em-country-select>
      </div>
    </div>
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <span>{{ $t('MBO.RISK_LEVEL') }}</span>
        <q-slider
          v-model="riskLevelCountry.riskLevel"
          :min="MinRiskLevel"
          :max="MaxRiskLevel"
          class="q-px-sm"
          markers
          marker-labels
        >
          <template v-slot:marker-label-group="{ markerList }">
            <div
              v-for="(item, index) in MaxRiskLevel"
              :key="item"
              :class="markerList[index].classes"
              :style="markerList[index].style"
              class="cursor-pointer"
              @click="onRiskLevelClick(item)"
            >
              <span>{{ item }}</span>
            </div>
          </template>
        </q-slider>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import i18n from '@/i18n';
  import { MinRiskLevel, MaxRiskLevel } from '@/components/risk-level/risk-level.constant';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import CountryStore from '@/components/risk-level/countries/country/country.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state, dispatch, commit } = useStore();
      const riskLevelCountry = computed(() => state[CountriesStore.name][CountryStore.name].riskLevelCountry);
      const sourceOptions = computed(() => state[CountriesStore.name][CountryStore.name].sourceOptions);
      const categoryOptions = computed(() => state[CountriesStore.name][CountryStore.name].categoryOptions);
      onMounted(() => {
        dispatch(`${CountriesStore.name}/${CountryStore.name}/getDictionaries`);
      });
      return {
        v$: ref(props.validations),
        MinRiskLevel,
        MaxRiskLevel,
        riskLevelCountry,
        sourceOptions,
        categoryOptions,
        optionLabel(payload) {
          return payload.name[i18n.global.locale] || null;
        },
        categoryModel: computed({
          get() {
            const { code } = riskLevelCountry.value.category;
            return code ? riskLevelCountry.value.category : null;
          },
          set(payload) {
            commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`, {
              ...riskLevelCountry.value,
              category: payload,
            });
          },
        }),
        sourceModel: computed({
          get() {
            const { code } = riskLevelCountry.value.source;
            return code ? riskLevelCountry.value.source : null;
          },
          set(payload) {
            commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`, {
              ...riskLevelCountry.value,
              source: payload,
            });
          },
        }),
        onRiskLevelClick(payload) {
          commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`, {
            ...riskLevelCountry.value,
            riskLevel: payload,
          });
        },
      };
    },
  });
</script>
