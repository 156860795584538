<template>
  <div :class="$style.component">
    <div class="q-pa-md overflow-auto">
      <header-component></header-component>
      <form-component :validations="v$"></form-component>
    </div>
    <q-space></q-space>
    <footer-component :validations="v$"></footer-component>
  </div>
</template>
<script>
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import { Language } from '@/common/constants/languages';
  import HeaderComponent from '@/components/risk-level/categories/category/components/header.component.vue';
  import FormComponent from '@/components/risk-level/categories/category/components/form.component.vue';
  import FooterComponent from '@/components/risk-level/categories/category/components/footer.component.vue';
  import Store from '@/store';
  import CategoriesStore from '@/components/risk-level/categories/categories.store';
  import CategoryStore from '@/components/risk-level/categories/category/category.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const { id } = to.params;
        if (id) {
          await Store.dispatch(`${CategoriesStore.name}/${CategoryStore.name}/getRiskLevelCategoryById`, id);
        } else {
          Store.commit(`${CategoriesStore.name}/${CategoryStore.name}/setRiskLevelCategory`);
        }
        next();
      } catch (error) {
        next({ name: 'risk-level.categories.search' });
        throw error;
      }
    },
    setup() {
      const { state } = useStore();
      const v$ = useVuelidate();
      const riskLevelCategory = computed(() => state[CategoriesStore.name][CategoryStore.name].riskLevelCategory);
      return {
        v$,
        riskLevelCategory,
      };
    },
    validations() {
      return {
        riskLevelCategory: {
          name: {
            [Language.en]: { required },
            [Language.fr]: { required },
          },
          code: { required },
        },
      };
    },
    components: {
      HeaderComponent,
      FormComponent,
      FooterComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/item-component';

  .component {
    @include item-component();
  }
</style>
