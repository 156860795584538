<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="riskLevelSource.id">{{ $t('MBO.MODIFY_RISK_LEVEL_SOURCE') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_RISK_LEVEL_SOURCE') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import SourcesStore from '@/components/risk-level/sources/sources.store';
  import SourceStore from '@/components/risk-level/sources/source/source.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const riskLevelSource = computed(() => state[SourcesStore.name][SourceStore.name].riskLevelSource);
      return {
        riskLevelSource,
      };
    },
  });
</script>
