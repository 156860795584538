<template>
  <em-table
    v-model:pagination="searchOutput.pagination"
    :fetch="getRiskLevelCountries"
    :columns="SearchColumns"
    :rows="searchOutput.content"
    :title="$t('MBO.COUNTRIES')"
  >
    <template #actions="{ item }">
      <q-btn
        v-if="hasFeature(Feature.riskLevelCategoriesWrite)"
        dense
        flat
        round
        icon="create"
        @click="onEditClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.MODIFY') }}</span>
        </q-tooltip>
      </q-btn>
      <q-btn
        v-if="hasFeature(Feature.riskLevelCountriesDelete)"
        dense
        flat
        round
        icon="delete"
        @click="onDeleteClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.DELETE') }}</span>
        </q-tooltip>
      </q-btn>
    </template>
  </em-table>
</template>
<script>
  import { computed, defineComponent, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';
  import { SearchColumns } from '@/components/risk-level/countries/search/search.constant';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import SearchStore from '@/components/risk-level/countries/search/search.store';
  import CountryStore from '@/components/risk-level/countries/country/country.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const searchInput = computed(() => state[CountriesStore.name][SearchStore.name].searchInput);
      const searchOutput = computed(() => state[CountriesStore.name][SearchStore.name].searchOutput);
      const $confirm = inject('$confirm');

      function getRiskLevelCountries(payload) {
        if (payload) {
          commit(`${CountriesStore.name}/${SearchStore.name}/setSearchInput`, {
            ...searchInput.value,
            ...payload,
          });
        }
        dispatch(`${CountriesStore.name}/${SearchStore.name}/getRiskLevelCountries`, searchInput.value.searchObj);
      }
      return {
        hasFeature,
        Feature,
        SearchColumns,
        searchInput,
        searchOutput,
        getRiskLevelCountries,
        onEditClick(payload) {
          commit(`${CountriesStore.name}/${CountryStore.name}/setRiskLevelCountry`, payload);
          router.push({ name: 'risk-level.countries.country', params: { id: payload.id } });
        },
        async onDeleteClick({ id }) {
          const result = await $confirm({
            content: t('MBO.ARE_YOU_SURE_DELETE_ITEM'),
          });
          if (result) {
            try {
              await dispatch(`${CountriesStore.name}/${SearchStore.name}/deleteRiskLevelCountryById`, id);
              await getRiskLevelCountries();
              $q.notify(t('MBO.RISK_LEVEL_COUNTRY_WAS_DELETED'));
            } catch (error) {
              $q.notify(t('MBO.SOMETHING_WENT_WRONG'));
              throw error;
            }
          }
        },
      };
    },
  });
</script>
