<template>
  <router-view></router-view>
</template>
<script>
  import { defineComponent, onUnmounted } from 'vue';
  import Store from '@/store';
  import CategoriesStore from '@/components/risk-level/categories/categories.store';

  export default defineComponent({
    beforeRouteEnter(to, from, next) {
      Store.registerModule(CategoriesStore.name, CategoriesStore.module);
      next();
    },
    setup() {
      onUnmounted(() => {
        Store.unregisterModule(CategoriesStore.name);
      });
    },
  });
</script>
