<template>
  <footer>
    <q-separator></q-separator>
    <div class="q-px-md q-py-xs flex">
      <q-space></q-space>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.BACK')"
        class="q-ma-xs"
        @click="onBackClick"
      ></q-btn>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.SAVE')"
        class="q-ma-xs"
        color="primary"
        @click="onSaveClick()"
      ></q-btn>
    </div>
  </footer>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import CategoriesStore from '@/components/risk-level/categories/categories.store';
  import CategoryStore from '@/components/risk-level/categories/category/category.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state, dispatch } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const riskLevelCategory = computed(() => state[CategoriesStore.name][CategoryStore.name].riskLevelCategory);
      return {
        onBackClick() {
          router.push({ name: 'risk-level.categories.search' });
        },
        async onSaveClick() {
          props.validations.riskLevelCategory.$touch();
          if (props.validations.riskLevelCategory.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
          } else {
            await dispatch(`${CategoriesStore.name}/${CategoryStore.name}/saveRiskLevelCategory`, riskLevelCategory.value);
            $q.notify(t('MBO.RISK_LEVEL_CATEGORY_WAS_SAVED'));
            await router.push({ name: 'risk-level.categories.search' });
          }
        },
      };
    },
  });
</script>
