<template>
  <div class="q-gutter-md">
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <em-input-languages :errors="[v$.riskLevelCategory.name]">
          <template #input="{ code }">
            <div class="q-gutter-md">
              <q-input
                v-model="riskLevelCategory.name[code]"
                :error="v$.riskLevelCategory.name[code] && v$.riskLevelCategory.name[code].$error"
                :label="$t(`MBO.NAME_${code}`)"
                hide-bottom-space
                outlined
                dense
              ></q-input>
            </div>
          </template>
        </em-input-languages>
      </div>
    </div>
    <div class="row q-col-gutter-sm justify-center">
      <div class="col-12 col-sm-6">
        <q-input
          v-model="riskLevelCategory.code"
          :error="v$.riskLevelCategory.code.$error"
          :label="$t('MBO.CODE')"
          outlined
          dense
        ></q-input>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import CategoriesStore from '@/components/risk-level/categories/categories.store';
  import CategoryStore from '@/components/risk-level/categories/category/category.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state } = useStore();
      const riskLevelCategory = computed(() => state[CategoriesStore.name][CategoryStore.name].riskLevelCategory);
      return {
        v$: ref(props.validations),
        riskLevelCategory,
      };
    },
  });
</script>
