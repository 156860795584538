<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="riskLevelCountry.id">{{ $t('MBO.MODIFY_RISK_LEVEL_COUNTRY') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_RISK_LEVEL_COUNTRY') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import CountriesStore from '@/components/risk-level/countries/countries.store';
  import CountryStore from '@/components/risk-level/countries/country/country.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const riskLevelCountry = computed(() => state[CountriesStore.name][CountryStore.name].riskLevelCountry);
      return {
        riskLevelCountry,
      };
    },
  });
</script>
