<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="riskLevelCategory.id">{{ $t('MBO.MODIFY_RISK_LEVEL_CATEGORY') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_RISK_LEVEL_CATEGORY') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import CategoriesStore from '@/components/risk-level/categories/categories.store';
  import CategoryStore from '@/components/risk-level/categories/category/category.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const riskLevelCategory = computed(() => state[CategoriesStore.name][CategoryStore.name].riskLevelCategory);
      return {
        riskLevelCategory,
      };
    },
  });
</script>
